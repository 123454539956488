<template>
  <div class="home">
    <a href="/" id="logo"></a>
    <div id="logo-next">
      <span>Allianz</span>
      <span>Trade</span>
    </div>
    <h2 id="title" class="barchart-vertical"><span>Exports</span> (USD bn)</h2>
    <v-chart
      :option="bar"
      ref="bar"
      autoresize
    />
    <div class="year year-barchart-vertical">{{ currentYear }}</div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions } from 'vuex'

export default {
  name: 'Nine',
  computed: {
    nine() { return this.$store.getters.nine }
  },
  data() {
    return {
      startYear: null,
      currentYear: null,
      endYear: null,
      updateFrequency: 2000,
      interval: null,
      // colors: [
      //   {sector: null, color: "#122b54"},
      //   {sector: null, color: "#006192"},
      //   {sector: null, color: "#13a0d3"},
      //   {sector: null, color: "#b5dae6"},
      //   {sector: null, color: "#00908d"},
      //   {sector: null, color: "#5fcd8a"},
      //   {sector: null, color: "#a6276f"},
      //   {sector: null, color: "#f62459"},
      //   {sector: null, color: "#f86200"},
      //   {sector: null, color: "#fab600"},
      //   {sector: null, color: "#007ab3"},
      //   {sector: null, color: "#5b5763"},
      //   {sector: null, color: "#5fcd8a"},
      //   {sector: null, color: "#5fcd8a"},
      //   {sector: null, color: "#5fcd8a"},
      // ],
      bar: {
        yAxis: {
          max: 'dataMax',
          min: -50,
          max: 50
        },
        xAxis: {
          type: 'category',
          data: [],
          inverse: false,
          animationDuration: 300,
          animationDurationUpdate: 300,
          max: 9, // only the largest 3 bars will be displayed
          axisLabel: {
            show: true,
            interval: 0,
            fontSize: 14,
            fontWeight: 700,
            fontFamily: "AllianzNeo-Regular",
            color: "#414141",
            formatter: function(value, index) {
              return value.replaceAll(' ', '\n')
            }
          }
        },
        //color: ["#122b54", "#006192", "#13a0d3", "#b5dae6", "#00908d", "#5fcd8a", "#a6276f", "#f62459", "#f86200", "#fab600"],
        series: [
          {
            //realtimeSort: true,
            name: 'X',
            type: 'bar',
            data: [],
            label: {
              show: true,
              position: 'top',
              valueAnimation: true,
              fontSize: 18,
              fontWeight: 700,
              fontFamily: "AllianzNeo-Regular",
              color: "#414141"
            }
          }
        ],
        legend: {
          show: false
        },
        animationDuration: 0,
        animationDurationUpdate: 350,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear'
      }
    }
  },
  mounted() {
    this.getData('nine')
  },
  methods: {
    ...mapActions(['getData']),
    // color(sector) {
    //   // ["#122b54", "#006192", "#13a0d3", "#b5dae6", "#00908d", "#5fcd8a", "#a6276f", "#f62459", "#f86200", "#fab600"]
    //   for (let c in this.colors) {
    //     if (sector == this.colors[c]['country']) {
    //       return this.colors[c]['color']
    //     }
    //   }
    //   return '#5b5763'
    // },
    color(country) {
      if (country.toLowerCase() == "belgium") return "#003781"
      if (country.toLowerCase() == "canada") return "#122854"
      if (country.toLowerCase() == "china") return "#122854"
      if (country.toLowerCase() == "france") return "#006192"
      if (country.toLowerCase() == "germany") return "#003781"
      if (country.toLowerCase() == "hong kong") return "#007ab3"
      if (country.toLowerCase() == "italy") return "#13a0d3"
      if (country.toLowerCase() == "japan") return "#b5dae6"
      if (country.toLowerCase() == "netherlands") return "#dfeff2"
      if (country.toLowerCase() == "russia") return "#122854"
      if (country.toLowerCase() == "saudi arabia") return "#007ab3"
      if (country.toLowerCase() == "south korea") return "#5fcd8a"
      if (country.toLowerCase() == "switzerland") return "#006192"
      if (country.toLowerCase() == "united kingdom") return "#00908d"
      if (country.toLowerCase() == "united states") return "#a6276f"
      return '#5b5763'
    },
    updateYear() {
      if (this.currentYear < this.endYear) {
        this.currentYear += 1
      } else {
        clearInterval(this.interval)
      }
      let that = this
      let categories = []
      let data = this.nine.slice(1).filter(function (d) {
        return d[3] === that.currentYear;
      })
      for (let i in data) {
        categories.push(data[i][2])
      }
      let rows = []
      for (let d in data) {
        //rows.push([data[d][1], data[d][2]])
        rows.push({value: data[d][1], itemStyle: {color: that.color(data[d][2])}})
      }
      this.bar.xAxis.data = categories
      this.bar.series[0].data = rows
      if (this.$refs.bar) this.$refs.bar.setOption(this.bar)
    },
    prepareYear() {
      let that = this
      let categories = []
      let data = this.nine.slice(1).filter(function (d) {
        return d[3] === that.currentYear;
      })
      for (let i in data) {
        categories.push(data[i][2])
      }
      let rows = []
      for (let d in data) {
        //rows.push([data[d][1], data[d][2]])
        rows.push({value: data[d][1], itemStyle: {color: that.color(data[d][2])}})
      }
      this.bar.xAxis.data = categories
      this.bar.series[0].data = rows
      this.$refs.bar.setOption(this.bar)
    },
    run() {
      this.interval = setInterval(this.updateYear, this.updateFrequency)
    }
  },
  watch: {
    nine(new_val) {
      //console.log(new_val)
      let that = this
      // for (let i = 0; i < that.colors.length; i++) {
      //   that.colors[i]['sector'] = new_val[i + 1][1]
      // }
      this.currentYear = new_val[1][3]
      this.startYear = new_val[1][3]
      this.endYear = new_val[new_val.length - 1][3]
      // this.bar.series[0].itemStyle = {
      //   color: function(param) {
      //     //let label = param.data[1].split(' ')
      //     //let name = label.slice(0, -1).join(' ')
      //     //return that.color(name)
      //     return that.color(param.data[1])
      //   }
      // }
      this.prepareYear()
      this.run()
    }
  }
}
</script>

<template>
  <div class="home line-chart">
    <a href="/" id="logo"></a>
    <div id="logo-next">
      <span>Allianz</span>
      <span>Trade</span>
    </div>
    <h2 id="title" class="line-chart"><span>Global trade</span> growth</h2>
    <v-chart
      :option="line"
      ref="line"
      autoresize
    />
    <div class="line-text" v-html="currentText"></div>
    <div class="year line-chart">{{ currentYear }}</div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions } from 'vuex'

export default {
  name: 'Five',
  computed: {
    five() { return this.$store.getters.five }
  },
  data() {
    return {
      startYear: null,
      currentYear: null,
      dates: [],
      currentIndexYear: 0,
      endYear: null,
      updateFrequency: 2050,
      interval: null,
      texts: [
        "High increase in oil prices<br>following the 1979 crisis",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "The European Economic Communit<br>becomes he European Union",
        "NAFTA is implemented",
        "The WTO is created",
        "",
        "",
        "",
        "",
        "",
        "Bursting of the dot-com bubble and collapse<br>in global stock markets. China joins WTO",
        "The Euro zone is launched",
        "",
        "",
        "",
        "",
        "",
        "2008-2009 Global financial crisis",
        "2008-2009 Global financial crisis",
        "",
        "",
        "EU sovereign debt crisis",
        "China introduces the Belt and Road Initiative",
        "",
        "Falling commodities prices, Chinese economic slowdown,<br>exchange rate volatility",
        "The UK votes to leave the EU",
        "",
        "US-China trade tensions",
        "US-China trade tensions",
        "Covid-19 crisis"
      ],
      currentText: null,
      line: {
        animationDuration: 83000,
        dataset: [
          {
            id: 'dataset_raw',
            source: [['Value', 'Type', 'Year'],
            [22.43, 'in_value', 1980],
            [2.94, 'in_volume', 1980],
            [4.37, 'in_value', 1981],
            [3.26, 'in_volume', 1981],
            [-7.08, 'in_value', 1982],
            [-1.65, 'in_volume', 1982],
            [-2.78, 'in_value', 1983],
            [1.56, 'in_volume', 1983],
            [6.23, 'in_value', 1984],
            [8.62, 'in_volume', 1984],
            [2.46, 'in_value', 1985],
            [2.58, 'in_volume', 1985],
            [8.82, 'in_value', 1986],
            [4.51, 'in_volume', 1986],
            [17.52, 'in_value', 1987],
            [6.57, 'in_volume', 1987],
            [14.46, 'in_value', 1988],
            [8.87, 'in_volume', 1988],
            [8.11, 'in_value', 1989],
            [8.2, 'in_volume', 1989],
            [13.13, 'in_value', 1990],
            [6.84, 'in_volume', 1990],
            [3.32, 'in_value', 1991],
            [5.32, 'in_volume', 1991],
            [6.8, 'in_value', 1992],
            [5.55, 'in_volume', 1992],
            [-1.35, 'in_value', 1993],
            [2.96, 'in_volume', 1993],
            [14.24, 'in_value', 1994],
            [9.45, 'in_volume', 1994],
            [19.19, 'in_value', 1995],
            [9.54, 'in_volume', 1995],
            [4.58, 'in_value', 1996],
            [6.61, 'in_volume', 1996],
            [4.24, 'in_value', 1997],
            [10.17, 'in_volume', 1997],
            [-1.83, 'in_value', 1998],
            [4.86, 'in_volume', 1998],
            [5.16, 'in_value', 1999],
            [5.05, 'in_volume', 1999],
            [14.03, 'in_value', 2000],
            [12.41, 'in_volume', 2000],
            [-3.53, 'in_value', 2001],
            [0.6, 'in_volume', 2001],
            [4.45, 'in_value', 2002],
            [3.78, 'in_volume', 2002],
            [16.68, 'in_value', 2003],
            [5.86, 'in_volume', 2003],
            [21.4, 'in_value', 2004],
            [10.98, 'in_volume', 2004],
            [13.45, 'in_value', 2005],
            [7.95, 'in_volume', 2005],
            [15.07, 'in_value', 2006],
            [9.45, 'in_volume', 2006],
            [15.97, 'in_value', 2007],
            [8.01, 'in_volume', 2007],
            [15.89, 'in_value', 2008],
            [2.97, 'in_volume', 2008],
            [-23.35, 'in_value', 2009],
            [-10.33, 'in_volume', 2009],
            [22.03, 'in_value', 2010],
            [12.76, 'in_volume', 2010],
            [18.97, 'in_value', 2011],
            [7.05, 'in_volume', 2011],
            [1.02, 'in_value', 2012],
            [3.08, 'in_volume', 2012],
            [1.82, 'in_value', 2013],
            [3.51, 'in_volume', 2013],
            [0.51, 'in_value', 2014],
            [3.84, 'in_volume', 2014],
            [-12.63, 'in_value', 2015],
            [2.93, 'in_volume', 2015],
            [-2.97, 'in_value', 2016],
            [2.24, 'in_volume', 2016],
            [10.17, 'in_value', 2017],
            [5.59, 'in_volume', 2017],
            [10.1, 'in_value', 2018],
            [3.91, 'in_volume', 2018],
            [-2.61, 'in_value', 2019],
            [0.91, 'in_volume', 2019],
            [-7.53, 'in_value', 2020],
            [-8.2, 'in_volume', 2020]]
          }, {
            id: 'dataset_in_value',
            fromDatasetId: 'dataset_raw',
            transform: {
              type: 'filter',
              config: {
                and: [
                  { dimension: 'Year', gte: 1800 },
                  { dimension: 'Type', '=': 'in_value' }
                ]
              }
            }
          }, {
            id: 'dataset_in_volume',
            fromDatasetId: 'dataset_raw',
            transform: {
              type: 'filter',
              config: {
                and: [
                  { dimension: 'Year', gte: 1800 },
                  { dimension: 'Type', '=': 'in_volume' }
                ]
              }
            }
          }
        ],
        tooltip: {
          trigger: 'axis'
        },
        textStyle: {
          fontWeight: 700,
          fontSize: 18,
          fontFamily: "AllianzNeo-Regular",
          color: "#414141"
        },
        xAxis: {
          type: 'category',
          nameLocation: 'middle',
          axisLabel: {
            fontWeight: 700,
            fontSize: 18,
            fontFamily: "AllianzNeo-Regular",
            color: "#414141"
          }
        },
        yAxis: {
          name: 'Percentage value',
          axisLabel: {
            fontWeight: 700,
            fontSize: 18,
            fontFamily: "AllianzNeo-Regular",
            color: "#414141"
          }
        },
        series: [
          {
            type: 'line',
            datasetId: 'dataset_in_value',
            // showSymbol: false,
            symbolSize: 8,
            symbol: 'emptyCircle',
            smooth: true,
            encode: {
              x: 'Year',
              y: 'Value',
              // label: ['Type', 'Value'],
              itemName: 'Year',
              tooltip: ['Value']
            },
            endLabel: {
              show: true,
              formatter: function(params) {
                if (params.value[0] > 0) return 'In value: +' + Math.round(params.value[0] * 10) / 10 + '%'
                return 'In value: ' + Math.round(params.value[0] * 10) / 10 + '%'
              },
              fontSize: 22,
              fontWeight: 700,
              fontFamily: "AllianzNeo-Regular",
              verticalAlign: 'bottom'
            },
            lineStyle: {
              color: "#003781",
              width: 4,
              cap: 'round',
              join: 'round'
            }
          },
          {
            type: 'line',
            datasetId: 'dataset_in_volume',
            // showSymbol: false,
            symbolSize: 8,
            symbol: 'emptyCircle',
            smooth: true,
            encode: {
              x: 'Year',
              y: 'Value',
              // label: ['Type', 'Value'],
              itemName: 'Year',
              tooltip: ['Value']
            },
            endLabel: {
              show: true,
              formatter: function(params) {
                if (params.value[0] > 0) return 'In volume: +' + Math.round(params.value[0] * 10) / 10 + '%'
                return 'In volume: ' + Math.round(params.value[0] * 10) / 10 + '%'
              },
              fontSize: 22,
              fontWeight: 700,
              fontFamily: "AllianzNeo-Regular",
              verticalAlign: 'top'
            },
            lineStyle: {
              color: "#5fcd8a",
              width: 4,
              cap: 'round',
              join: 'round'
            }
          }
        ]
      }
    }
  },
  mounted() {
    this.getData('five')
  },
  methods: {
    ...mapActions(['getData']),
    run() {
      this.interval = setInterval(this.updateYear, this.updateFrequency)
    },
    updateYear() {
      if (this.currentYear < this.endYear) {
        this.currentIndexYear += 1
        this.currentYear = this.dates[this.currentIndexYear]
        this.currentText = this.texts[this.currentIndexYear]
      } else {
        clearInterval(this.interval)
      }
      // let line = this.line
      // line.series[0].data.push(this.in_value[this.currentIndexYear])
      // line.series[1].data.push(this.in_volume[this.currentIndexYear])
      // this.line.series[0].data.push(this.in_value[this.currentIndexYear])
      // this.line.series[1].data.push(this.in_volume[this.currentIndexYear])
      // this.$refs.line.setOption(this.line)
    },
  },
  watch: {
    five(new_val) {
      //console.log(new_val)
      let that = this
      that.dates = []
      that.currentIndexYear = 0
      that.in_value = []
      that.in_volume = []
      that.currentText = null
      // let min = 100
      // let max = -100
      for (let i in new_val) {
        if (i > 0) {
          if (i % 2 == 1) {
            that.dates.push(new_val[i][2])
          }
          // if (new_val[i][0] > max) max = new_val[i][0]
          // if (new_val[i][0] < min) min = new_val[i][0]
          if (new_val[i][1] == 'in_value') {
            that.in_value.push(new_val[i][0])
          } else {
            that.in_volume.push(new_val[i][0])
          }
          //that.line.dataset[0].source.push(new_val[i])
        }
      }
      this.currentYear = that.dates[0]
      this.startYear = that.dates[0]
      this.endYear = that.dates[that.dates.length - 1]
      this.currentText = this.texts[this.currentIndexYear]
      // // that.line.yAxis.min = Math.floor(min)
      // // that.line.yAxis.max = Math.ceil(max)
      that.line.yAxis.min = -30
      that.line.yAxis.max = 30

      // that.line.dataset[0].source[0].push()
      // that.line.dataset.push({
      //   id: 'dataset_raw',
      //   source: new_val
      // })
      // that.line.dataset.push({
      //   id: 'dataset_in_value',
      //   fromDatasetId: 'dataset_raw',
      //   transform: {
      //     type: 'filter',
      //     config: {
      //       and: [
      //         { dimension: 'Year', gte: 1800 },
      //         { dimension: 'Type', '=': 'in_value' }
      //       ]
      //     }
      //   }
      // })
      // that.line.dataset.push({
      //   id: 'dataset_in_volume',
      //   fromDatasetId: 'dataset_raw',
      //   transform: {
      //     type: 'filter',
      //     config: {
      //       and: [
      //         { dimension: 'Year', gte: 1800 },
      //         { dimension: 'Type', '=': 'in_volume' }
      //       ]
      //     }
      //   }
      // })
      // that.line.xAxis.data = that.dates
      // for (let i in that.in_value) {
      //   that.line.series[0].data.push(this.in_value[i])
      //   that.line.series[1].data.push(this.in_volume[i])
      // }
      that.run()
      // console.log(this.line)
      // this.$refs.line.setOption(this.line)
    }
  }
}
</script>

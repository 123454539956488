import Vue from 'vue'
import VueRouter from 'vue-router'
import First from '../views/First.vue'
import Second from '../views/Second.vue'
import Three from '../views/Three.vue'
import ThreeBis from '../views/Threebis.vue'
import Four from '../views/Four.vue'
import Five from '../views/Five.vue'
import Six from '../views/Six.vue'
import Seven from '../views/Seven.vue'
import Eight from '../views/Eight.vue'
import Nine from '../views/Nine.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/chart-1',
    name: 'first',
    component: First
  },
  {
    path: '/chart-2',
    name: 'second',
    component: Second
  },
  {
    path: '/chart-3',
    name: 'three',
    component: Three
  },
  {
    path: '/chart-3-bis',
    name: 'three-bis',
    component: ThreeBis
  },
  {
    path: '/chart-4',
    name: 'four',
    component: Four
  },
  {
    path: '/chart-5',
    name: 'five',
    component: Five
  },
  {
    path: '/chart-6',
    name: 'six',
    component: Six
  },
  {
    path: '/chart-7',
    name: 'seven',
    component: Seven
  },
  {
    path: '/chart-8',
    name: 'eight',
    component: Eight
  },
  {
    path: '/chart-9',
    name: 'nine',
    component: Nine
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
  <div class="home">
    <a href="/" id="logo"></a>
    <div id="logo-next">
      <span>Allianz</span>
      <span>Trade</span>
    </div>
    <h2 id="title">Top 10 <span>exporters</span><br>(USD Bn)</h2>
    <v-chart
      :option="bar"
      ref="bar"
      autoresize
    />
    <div class="year">{{ currentYear }}</div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions } from 'vuex'

export default {
  name: 'Three',
  computed: {
    three() { return this.$store.getters.three }
  },
  data() {
    return {
      startYear: 1980,
      currentYear: 1980,
      endYear: 2020,
      updateFrequency: 1500,
      interval: null,
      bar: {
        xAxis: {
          max: 'dataMax'
        },
        yAxis: {
          type: 'category',
          data: [],
          inverse: true,
          animationDuration: 300,
          animationDurationUpdate: 300,
          max: 9, // only the largest 3 bars will be displayed
          axisLabel: {
            show: true,
            fontSize: 26,
            fontWeight: 700,
            fontFamily: "AllianzNeo-Regular",
            color: "#414141",
            // rich: {
            //   flag: {
            //     fontSize: 25,
            //     padding: 5
            //   }
            // }
          }
        },
        //color: ["#306CAF"],
        series: [
          {
            realtimeSort: true,
            name: 'X',
            type: 'bar',
            data: [],
            label: {
              show: true,
              position: 'right',
              valueAnimation: true,
              fontSize: 26,
              fontWeight: 700,
              fontFamily: "AllianzNeo-Regular",
              color: "#414141"
            },
          }
        ],
        legend: {
          show: false
        },
        animationDuration: 0,
        animationDurationUpdate: 350,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear'
      }
    }
  },
  mounted() {
    this.getData('three')
  },
  methods: {
    ...mapActions(['getData']),
    color(country) {
      if (country.toLowerCase() == "belgium") return "#fab600"
      if (country.toLowerCase() == "canada") return "#dfeff2"
      if (country.toLowerCase() == "china") return "#f62459"
      if (country.toLowerCase() == "france") return "#b5dae6"
      if (country.toLowerCase() == "germany") return "#fab600"
      if (country.toLowerCase() == "hong kong") return "#5fcd8a"
      if (country.toLowerCase() == "italy") return "#00908d"
      if (country.toLowerCase() == "japan") return "#122b54"
      if (country.toLowerCase() == "netherlands") return "#f86200"
      if (country.toLowerCase() == "russia") return "#f62459"
      if (country.toLowerCase() == "saudi arabia") return "#5fcd8a"
      if (country.toLowerCase() == "south korea") return "#a6276f"
      if (country.toLowerCase() == "switzerland") return "#b5dae6"
      if (country.toLowerCase() == "united kingdom") return "#13a0d3"
      if (country.toLowerCase() == "united states") return "#007ab3"
      return '#5b5763'
    },
    updateYear() {
      if (this.currentYear < this.endYear) {
        this.currentYear += 1
      } else {
        clearInterval(this.interval)
      }
      let that = this
      let categories = []
      let data = this.three.slice(1).filter(function (d) {
        return d[3] === that.currentYear;
      })
      for (let i in data) {
        //categories.push(data[i][2] + ' ' + data[i][0])
        categories.push(data[i][2])
      }
      let rows = []
      for (let d in data) {
        //rows.push([data[d][1], data[d][2] + ' ' + data[d][0]])
        rows.push([data[d][1], data[d][2]])
      }
      this.bar.yAxis.data = categories
      this.bar.series[0].data = rows
      if (this.$refs.bar) this.$refs.bar.setOption(this.bar)
    },
    prepareYear() {
      let that = this
      let categories = []
      let data = this.three.slice(1).filter(function (d) {
        return d[3] === that.currentYear;
      })
      for (let i in data) {
        //categories.push(data[i][2] + ' ' + data[i][0])
        categories.push(data[i][2])
      }
      let rows = []
      for (let d in data) {
        //rows.push([data[d][1], data[d][2] + ' ' + data[d][0]])
        rows.push([data[d][1], data[d][2]])
      }
      this.bar.yAxis.data = categories
      this.bar.series[0].data = rows
      this.$refs.bar.setOption(this.bar)
    },
    run() {
      this.interval = setInterval(this.updateYear, this.updateFrequency)
    }
  },
  watch: {
    three(new_val) {
      let that = this
      this.currentYear = new_val[1][3]
      this.startYear = new_val[1][3]
      this.endYear = new_val[new_val.length - 1][3]
      this.bar.series[0].itemStyle = {
        color: function(param) {
          // let label = param.data[1].split(' ')
          // let name = label.slice(0, -1).join(' ')
          // return that.color(name)
          return that.color(param.data[1])
        }
      }
      this.prepareYear()
      this.run()
    }
  }
}
</script>

<template>
  <div class="home">
    <a href="/" id="logo"></a>
    <div id="logo-next">
      <span>Allianz</span>
      <span>Trade</span>
    </div>
    <h2 id="title"><span>Tariff rate</span> applied<br>by today's top importers (%)</h2>
    <v-chart
      :option="bar"
      ref="bar"
      autoresize
    />
    <div class="year">{{ currentYear }}</div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions } from 'vuex'

export default {
  name: 'Six',
  computed: {
    six() { return this.$store.getters.six }
  },
  data() {
    return {
      startYear: 2000,
      currentYear: 2000,
      endYear: 2020,
      updateFrequency: 1500,
      interval: null,
      colors: [
        {country: null, color: "#003781"},
        {country: null, color: "#122854"},
        {country: null, color: "#006192"},
        {country: null, color: "#007ab3"},
        {country: null, color: "#13a0d3"},
        {country: null, color: "#b5dae6"},
        {country: null, color: "#dfeff2"},
        {country: null, color: "#5fcd8a"},
        {country: null, color: "#00908d"}
      ],
      bar: {
        xAxis: {
          max: 'dataMax'
        },
        yAxis: {
          type: 'category',
          data: [],
          inverse: true,
          animationDuration: 300,
          animationDurationUpdate: 300,
          max: 8, // only the largest 3 bars will be displayed
          axisLabel: {
            show: true,
            fontSize: 24,
            fontWeight: 700,
            fontFamily: "AllianzNeo-Regular",
            color: "#414141",
            rich: {
              flag: {
                fontSize: 25,
                padding: 5
              }
            }
          }
        },
        //color: ["#122b54", "#006192", "#13a0d3", "#b5dae6", "#00908d", "#5fcd8a", "#a6276f", "#f62459", "#f86200", "#fab600"],
        series: [
          {
            realtimeSort: true,
            name: 'X',
            type: 'bar',
            data: [],
            label: {
              show: true,
              position: 'right',
              valueAnimation: true,
              fontSize: 26,
              fontWeight: 700,
              fontFamily: "AllianzNeo-Regular",
              color: "#414141"
            }
          }
        ],
        legend: {
          show: false
        },
        animationDuration: 0,
        animationDurationUpdate: 350,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear'
      }
    }
  },
  mounted() {
    this.getData('six')
  },
  methods: {
    ...mapActions(['getData']),
    color(country) {
      // ["#122b54", "#006192", "#13a0d3", "#b5dae6", "#00908d", "#5fcd8a", "#a6276f", "#f62459", "#f86200", "#fab600"]
      for (let c in this.colors) {
        if (country == this.colors[c]['country']) {
          return this.colors[c]['color']
        }
      }
      return '#5b5763'
    },
    updateYear() {
      if (this.currentYear < this.endYear) {
        this.currentYear += 1
      } else {
        clearInterval(this.interval)
      }
      let that = this
      let categories = []
      let data = this.six.slice(1).filter(function (d) {
        return d[3] === that.currentYear;
      })
      for (let i in data) {
        //categories.push(data[i][2] + ' ' + data[i][0])
        categories.push(data[i][2])
      }
      let rows = []
      for (let d in data) {
        //rows.push([data[d][1], data[d][2] + ' ' + data[d][0]])
        rows.push([data[d][1], data[d][2]])
      }
      this.bar.yAxis.data = categories
      this.bar.series[0].data = rows
      if (this.$refs.bar) this.$refs.bar.setOption(this.bar)
    },
    prepareYear() {
      let that = this
      let categories = []
      let data = this.six.slice(1).filter(function (d) {
        return d[3] === that.currentYear;
      })
      for (let i in data) {
        //categories.push(data[i][2] + ' ' + data[i][0])
        categories.push(data[i][2])
      }
      let rows = []
      for (let d in data) {
        //rows.push([data[d][1], data[d][2] + ' ' + data[d][0]])
        rows.push([data[d][1], data[d][2]])
      }
      this.bar.yAxis.data = categories
      this.bar.series[0].data = rows
      this.$refs.bar.setOption(this.bar)
    },
    run() {
      this.interval = setInterval(this.updateYear, this.updateFrequency)
    }
  },
  watch: {
    six(new_val) {
      //console.log(new_val)
      let that = this
      for (let i = 0; i < that.colors.length - 1; i++) {
        that.colors[i]['country'] = new_val[i + 1][2]
      }
      this.currentYear = new_val[1][3]
      this.startYear = new_val[1][3]
      this.endYear = new_val[new_val.length - 1][3]
      this.bar.series[0].itemStyle = {
        color: function(param) {
          // let label = param.data[1].split(' ')
          // let name = label.slice(0, -1).join(' ')
          // return that.color(name)
          return that.color(param.data[1])
        }
      }
      this.prepareYear()
      this.run()
    }
  }
}
</script>

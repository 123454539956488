import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    first: {},
    second: {},
    three: [],
    four: [],
    five: [],
    six: [],
    seven: [],
    eight: [],
    nine: [],
  },
  getters: {
    first: state => state.first,
    second: state => state.second,
    three: state => state.three,
    four: state => state.four,
    five: state => state.five,
    six: state => state.six,
    seven: state => state.seven,
    eight: state => state.eight,
    nine: state => state.nine,
  },
  mutations: {
    setData(state, obj) {
      if (obj.var == 'first') {
        state.first = obj.data
      } else if (obj.var == 'second') {
        state.second = obj.data
      } else if (obj.var == 'three') {
        state.three = obj.data
      } else if (obj.var == 'four') {
        state.four = obj.data
      } else if (obj.var == 'five') {
        state.five = obj.data
      } else if (obj.var == 'six') {
        state.six = obj.data
      } else if (obj.var == 'seven') {
        state.seven = obj.data
      } else if (obj.var == 'eight') {
        state.eight = obj.data
      } else if (obj.var == 'nine') {
        state.nine = obj.data
      }
    }
  },
  actions: {
    getData({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_BO + '/' + payload, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          commit('setData', {data: response.data, var: payload})
        })
      })
    },
  },
  modules: {
  }
})

<template>
  <div class="home">
    <a href="/" id="logo"></a>
    <div id="logo-next">
      <span>Allianz</span>
      <span>Trade</span>
    </div>
    <h2 id="title" class="barchart-vertical"><span>Exports</span> by sector (USD bn)</h2>
    <v-chart
      :option="bar"
      ref="bar"
      autoresize
    />
    <div class="year year-barchart-vertical">{{ currentYear }}</div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions } from 'vuex'

export default {
  name: 'Four',
  computed: {
    four() { return this.$store.getters.four }
  },
  data() {
    return {
      startYear: null,
      currentYear: null,
      endYear: null,
      updateFrequency: 1500,
      interval: null,
      colors: [
        // {sector: null, color: "#122b54"},
        // {sector: null, color: "#006192"},
        // {sector: null, color: "#13a0d3"},
        // {sector: null, color: "#b5dae6"},
        // {sector: null, color: "#00908d"},
        // {sector: null, color: "#5fcd8a"},
        // {sector: null, color: "#a6276f"},
        // {sector: null, color: "#f62459"},
        // {sector: null, color: "#f86200"},
        // {sector: null, color: "#fab600"},
        // {sector: null, color: "#007ab3"},
        // {sector: null, color: "#5b5763"},
        // {sector: null, color: "#5fcd8a"},
        // {sector: null, color: "#5fcd8a"},
        // {sector: null, color: "#5fcd8a"},
        {sector: null, color: "#00908d"},
        {sector: null, color: "#a6276f"},
        {sector: null, color: "#5fcd8a"},
        {sector: null, color: "#13a0d3"},
        {sector: null, color: "#007ab3"},
        {sector: null, color: "#006192"},
        {sector: null, color: "#b5dae6"},
        {sector: null, color: "#13a0d3"},
        {sector: null, color: "#003781"},
        {sector: null, color: "#003781"},
        {sector: null, color: "#007ab3"},
        {sector: null, color: "#5b5763"},
        {sector: null, color: "#dfeff2"},
        {sector: null, color: "#dfeff2"},
        {sector: null, color: "#dfeff2"},
      ],
      bar: {
        yAxis: {
          max: 'dataMax'
        },
        xAxis: {
          type: 'category',
          data: [],
          inverse: false,
          animationDuration: 300,
          animationDurationUpdate: 300,
          max: 9, // only the largest 3 bars will be displayed
          axisLabel: {
            show: true,
            interval: 0,
            fontSize: 20,
            fontWeight: 700,
            fontFamily: "AllianzNeo-Regular",
            color: "#414141",
            formatter: function(value, index) {
              return value.replaceAll(' ', '\n')
            }
          }
        },
        //color: ["#122b54", "#006192", "#13a0d3", "#b5dae6", "#00908d", "#5fcd8a", "#a6276f", "#f62459", "#f86200", "#fab600"],
        series: [
          {
            realtimeSort: true,
            name: 'X',
            type: 'bar',
            data: [],
            label: {
              show: true,
              position: 'top',
              valueAnimation: true,
              fontSize: 28,
              fontWeight: 700,
              fontFamily: "AllianzNeo-Regular",
              color: "#414141"
            }
          }
        ],
        legend: {
          show: false
        },
        animationDuration: 0,
        animationDurationUpdate: 350,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear'
      }
    }
  },
  mounted() {
    this.getData('four')
  },
  methods: {
    ...mapActions(['getData']),
    color(sector) {
      // ["#122b54", "#006192", "#13a0d3", "#b5dae6", "#00908d", "#5fcd8a", "#a6276f", "#f62459", "#f86200", "#fab600"]
      for (let c in this.colors) {
        if (sector == this.colors[c]['sector']) {
          return this.colors[c]['color']
        }
      }
      return '#5b5763'
    },
    updateYear() {
      if (this.currentYear < this.endYear) {
        this.currentYear += 1
      } else {
        clearInterval(this.interval)
      }
      let that = this
      let categories = []
      let data = this.four.slice(1).filter(function (d) {
        return d[2] === that.currentYear;
      })
      for (let i in data) {
        categories.push(data[i][1])
      }
      let rows = []
      for (let d in data) {
        //rows.push([data[d][1], data[d][0]])
        rows.push({value: data[d][0], itemStyle: {color: that.color(data[d][1])}})
      }
      this.bar.xAxis.data = categories
      this.bar.series[0].data = rows
      if (this.$refs.bar) this.$refs.bar.setOption(this.bar)
    },
    prepareYear() {
      let that = this
      let categories = []
      let data = this.four.slice(1).filter(function (d) {
        return d[2] === that.currentYear;
      })
      for (let i in data) {
        categories.push(data[i][1])
      }
      let rows = []
      for (let d in data) {
        //rows.push([data[d][1], data[d][0]])
        rows.push({value: data[d][0], itemStyle: {color: that.color(data[d][1])}})
      }
      this.bar.xAxis.data = categories
      this.bar.series[0].data = rows
      this.$refs.bar.setOption(this.bar)
    },
    run() {
      this.interval = setInterval(this.updateYear, this.updateFrequency)
    }
  },
  watch: {
    four(new_val) {
      let that = this
      for (let i = 0; i < that.colors.length; i++) {
        that.colors[i]['sector'] = new_val[i + 1][1]
      }
      this.currentYear = new_val[1][2]
      this.startYear = new_val[1][2]
      this.endYear = new_val[new_val.length - 1][2]
      // this.bar.series[0].itemStyle = {
      //   color: function(param) {
      //     let label = param.data[0].split(' ')
      //     let name = label.slice(0, -1).join(' ')
      //     return that.color(name)
      //   }
      // }
      this.prepareYear()
      this.run()
    }
  }
}
</script>
